<template>
	<div class="points-details">
		<div class="box">
			<div class="top">
				<span>{{ details.integral_notes }}</span>
				<div class="points">{{ details.integral }}</div>
			</div>

			<div class="desc">
				<div class="row">
					<div class="left">类型</div>
					<div class="right">{{ details.integral_types | integralTypes }}</div>
				</div>
				<div class="row">
					<div class="left">时间</div>
					<div class="right">{{ details.create_time }}</div>
				</div>
				<div class="row">
					<div class="left">订单编号</div>
					<div class="right">{{ details.order_no }}</div>
				</div>
				<div class="row">
					<div class="left">订单金额</div>
					<div class="right">￥{{ details.order_amount }}</div>
				</div>
			</div>
		</div>
	</div>

</template>
<style lang="scss" scoped>
	.points-details {
		background: #f6f6f6;
		box-sizing: border-box;
		height: 100vh;

		.box {
			padding: 0 3vw;
			background: #fff;
		}

		.top {
			// padding: 0 3vw;
			height: 2.3rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			font-size: .28rem;
			line-height: 2;
			border-bottom: 1px solid #eee;

			.points {
				font-size: .48rem;
				font-weight: bold;
			}

		}

		.desc {
			padding: 20px 0;

			.row {
				height: .82rem;
				display: flex;
				align-items: center;
				font-size: .28rem;

				.left {
					color: #595959;
					width: 4em;
					margin-right: 1rem;
				}
			}
		}
	}
</style>
<script>
	import {
		getIntegralDetails
	} from "@/http/api";

	export default {
		components: {},
		data() {
			return {
				id: this.$route.query.id,
				details: {}
			};
		},
		mounted() {
			if((this.userInfo = this.$login.isLogin(window.location.href))) {
				this.getIntegralDetails();
			}
		},
		methods: {
			// 获取积分详情
			async getIntegralDetails() {
				const res = await getIntegralDetails({
					user_id: this.userInfo.user_id,
					integral_id: this.id
				})
				this.details = res;
			}
		},
		computed: {

		},
		filters: {
			integralTypes(data) {
				switch(data) {
					case 1:
						return '获取';
					case 2:
						return '消耗'
				}
			}
		}
	};
</script>
